<template>
  <div>
    <ModalRightDynamic @closeModal="handleClose">
      <template #title> {{ $t(title) }} </template>
      <template #content-form>
        <v-form ref="modalTask" class="mt-2" v-if="eventToUse">
          <!-- NOM EVENT -->
          <v-row class="row">
            <v-col>
              <v-text-field
                :label="$t('name')"
                dense
                :persistent-placeholder="true"
                outlined
                color="#5C2DD3"
                v-model="eventToUse.name"
                :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                required
                item-color="#5C2DD3"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- DESCRIPTION -->
          <v-row class="row">
            <v-col>
              <v-textarea
                dense
                :label="$t('description')"
                v-model="eventToUse.detail"
                :persistent-placeholder="true"
                rows="3"
                outlined
                color="#5C2DD3"
              ></v-textarea>
            </v-col>
          </v-row>

          <!-- Premier composant pour la date de début -->
          <v-row class="mb-2">
            <v-col cols="6">
              <!-- Vue CTK Date Time Picker pour la date de début -->
              <vue-ctk-date-time-picker
                v-model="eventToUse.start"
                color="#45148F"
                :inline="false"
                :buttonNowTranslation="'Today'"
                inputSize="sm"
                buttonColor="#45148F"
                locale="fr"
                label="Start Date"
                class="custom-ctk-component"
                format="YYYY-MM-DD HH:mm"
                :no-value-to-custom-elem="true"
                @input="checkRulesDate(['dateStart', 'dateEnd'])"
              >
                <v-text-field
                  v-model="eventToUse.start"
                  :label="$t('date_debut')"
                  @click:clear="eventToUse.start = null"
                  outlined
                  readonly
                  clearable
                  dense
                  ref="dateStart"
                  :persistent-placeholder="true"
                  :placeholder="$t('msgSelectDate')"
                  prepend-inner-icon="mdi-calendar"
                  color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('date_debut') + ' ' + $t('msgOblg')]"
                ></v-text-field> </vue-ctk-date-time-picker
            ></v-col>

            <!-- Deuxième composant pour la date de fin -->
            <!-- Vue CTK Date Time Picker pour la date de fin -->
            <v-col cols="6">
              <vue-ctk-date-time-picker
                v-model="eventToUse.end"
                color="#45148F"
                :inline="false"
                :buttonNowTranslation="'Today'"
                inputSize="sm"
                buttonColor="#45148F"
                locale="fr"
                label="End Date"
                class="custom-ctk-component right-position "
                format="YYYY-MM-DD HH:mm"
                :no-value-to-custom-elem="true"
                @input="checkRulesDate('dateEnd')"
              >
                <v-text-field
                  v-model="eventToUse.end"
                  :label="$t('date_fin')"
                  @click:clear="eventToUse.end = null"
                  outlined
                  readonly
                  clearable
                  ref="dateEnd"
                  dense
                  :persistent-placeholder="true"
                  :placeholder="$t('msgSelectDate')"
                  prepend-inner-icon="mdi-calendar"
                  color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('date_fin') + ' ' + $t('msgOblg')]"
                ></v-text-field> </vue-ctk-date-time-picker
            ></v-col>
          </v-row>

          <!-- DATE DEBUT / DATE FIN -->
          <!-- <v-row>
            <v-col>
              <v-menu
                :close-on-content-click="false"
                offset-y
                content-class="input-date-dynamique custom-menu-position"
                @input="handleOpenDateDebut"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventToUse.start"
                    :value="eventToUse.start"
                    :label="$t('date_debut')"
                    @input="affectEndDate"
                    @click:clear="eventToUse.start = null"
                    outlined
                    readonly
                    clearable
                    dense
                    ref="dateStart"
                    v-on="on"
                    v-bind="attrs"
                    :persistent-placeholder="true"
                    :placeholder="$t('msgSelectDate')"
                    prepend-inner-icon="mdi-calendar"
                    color="#5C2DD3"
                    required
                    validate-on-blur
                    :rules="[
                      v => !!v || $t('date_debut') + ' ' + $t('msgOblg')
                    ]"
                  ></v-text-field>
                </template>
                <v-card>
                  <v-card-text>
                    <v-date-picker
                      value-type="format"
                      format="YYYY-MM-DD HH:mm"
                      :value="selectedDateTimeDateStart"
                      v-model="selectedDateTimeDateStart"
                      no-title
                      locale="fr"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      @input="
                        handleDatePicker(selectedDateTimeDateStart, 'dateStart')
                      "
                    ></v-date-picker>
                    <v-time-picker
                      class="ml-3"
                      v-model="selectedDateTimeTimeStart"
                      :value="selectedDateTimeTimeStart"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      format="24hr"
                      @input="
                        handleDatePicker(selectedDateTimeTimeStart, 'dateStart')
                      "
                    ></v-time-picker>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                :close-on-content-click="false"
                offset-y
                content-class="input-date-dynamique custom-menu-position"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="eventToUse.end"
                    :value="eventToUse.end"
                    :label="$t('date_fin')"
                    @click:clear="eventToUse.end = null"
                    outlined
                    readonly
                    clearable
                    ref="dateEnd"
                    dense
                    v-on="on"
                    v-bind="attrs"
                    :persistent-placeholder="true"
                    :placeholder="$t('msgSelectDate')"
                    prepend-inner-icon="mdi-calendar"
                    color="#5C2DD3"
                    required
                    validate-on-blur
                    :rules="[v => !!v || $t('date_fin') + ' ' + $t('msgOblg')]"
                  ></v-text-field>
                </template>
                <v-card>
                  <v-card-text>
                    <v-date-picker
                      value-type="format"
                      format="YYYY-MM-DD HH:mm"
                      :value="selectedDateTimeDateEnd"
                      v-model="selectedDateTimeDateEnd"
                      no-title
                      locale="fr"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      @input="
                        handleDatePicker(selectedDateTimeDateEnd, 'dateEnd')
                      "
                    ></v-date-picker>
                    <v-time-picker
                      class="ml-3"
                      v-model="selectedDateTimeTimeEnd"
                      :value="selectedDateTimeTimeEnd"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      format="24hr"
                      @input="
                        handleDatePicker(selectedDateTimeTimeEnd, 'dateEnd')
                      "
                    ></v-time-picker>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row> -->
          <!-- STATUS EVENT -->
          <v-row>
            <v-col>
              <v-autocomplete
                :clearable="true"
                color="#5C2DD3"
                :placeholder="$t('event_status')"
                item-color="#5C2DD3"
                v-model="eventToUse.status"
                :items="getEventStatus"
                :persistent-placeholder="true"
                :label="$t('event_status')"
                item-text="text"
                dense
                item-value="value"
                outlined
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                required
                validate-on-blur
                :rules="[v => !!v || $t('event_status') + ' ' + $t('msgOblg')]"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <!-- ASSIGN USER TO EVENT -->
          <v-row>
            <v-col>
              <v-autocomplete
                :clearable="true"
                color="#5C2DD3"
                :placeholder="$t('assigned_to')"
                item-color="#5C2DD3"
                v-model="eventToUse.assigned_user_id"
                :items="allUsers"
                :persistent-placeholder="true"
                :label="$t('assigned_to')"
                item-text="full_name"
                dense
                item-value="id"
                outlined
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                required
                validate-on-blur
                :rules="[v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')]"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- TYPE EVENT -->
          <v-row>
            <v-col>
              <v-autocomplete
                :clearable="true"
                color="#5C2DD3"
                :loading="getTypeEventLoading"
                :placeholder="
                  $t('searchMsg', {
                    msg: $t('event_type')
                  })
                "
                item-color="#5C2DD3"
                v-model="eventToUse.type"
                :items="getTypeEvent"
                :persistent-placeholder="true"
                :label="$t('event_type')"
                item-text="designation"
                dense
                return-object
                item-value="id"
                :no-data-text="
                  $t('noDataOptionMsg', {
                    option: $t('event_type')
                  })
                "
                @change="handelChangeEventType"
                outlined
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                required
                validate-on-blur
                :rules="[v => !!v || $t('event_type') + ' ' + $t('msgOblg')]"
                :disabled="disabledType"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title class="title-list-item">
                        <div
                          class="line-color-item"
                          :style="'background-color :' + item.color"
                        ></div>
                        <div>
                          {{ item.designation }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- CHAMP DYNAMIQUE  -->
          <div v-if="eventToUse.fields">
            <v-row
              v-for="field in eventToUse.fields"
              :key="'field-add-' + field.id"
            >
              <v-col>
                <div class="value">
                  <EditableCustomFieldForm
                    :readonly="false"
                    :field="field"
                    :value="field.value"
                    :typeRessource="eventToUse.fields"
                    @updateFunction="updateFunction"
                    :fieldKeyEdit="false"
                    :required="field.required"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <!-- MESSAGE SUCCES  -->
        <div class="actions-msg-btn-modal">
          <div v-if="errorMsg" class="error-msg text-center">
            <ul v-if="Array.isArray(errorMsg)">
              <li
                v-for="(e, index) in errorMsg"
                :key="'get-projects-create-error-' + index"
              >
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ errorMsg }} </span>
          </div>
        </div>
      </template>
      <template #content-actions>
        <v-btn
          style="height: 30px;"
          color="#45148F"
          dark
          @click.prevent.stop="handleValiderTask"
          :loading="processLoader"
        >
          <span class="label-btn-form-immo text-uppercase">
            {{ $t('btnAdd') }}</span
          >
          <font-awesome-icon :icon="['fas', 'chevron-right']" class="ml-2" />
        </v-btn>
        <v-btn
          text
          class="ml-3"
          @click.prevent.stop="handleClose"
          variant="outline-secondary"
        >
          <span class="label-btn-form-immo color-black text-uppercase">
            {{ $t('btnCancel') }}</span
          >
        </v-btn></template
      >
    </ModalRightDynamic>
  </div>
</template>

<script>
import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'update-stage-modal',
  props: {
    task: {
      required: true
    },
    validateFunction: {
      required: true
    },
    disabledType: { default: true },
    title: { default: 'add_new_task' }
  },
  data() {
    return {
      eventToUse: { ...this.task },
      processLoader: false,
      errorMsg: null,
      selectedDateTimeDateStart: null,
      selectedDateTimeTimeStart: null,
      selectedDateTimeDateEnd: null,
      selectedDateTimeTimeEnd: null
    }
  },
  computed: {
    ...mapGetters([
      'getOneTypeEvent',
      'getTypeEventLoading',
      'getTypeEvent',
      'getEventStatus',
      'allUsers'
    ])
  },
  methods: {
    checkRulesDate(ref) {
      if (Array.isArray(ref)) {
        ref.forEach((item, index) => {
          setTimeout(() => {
            this.$refs[item].focus()
          }, index * 1000)
        })
      } else {
        this.$refs[ref].focus()
      }
    },
    handleClose() {
      this.$emit('close')
      this.processLoader = false
      this.errorMsg = null
      this.eventToUse = null
      this.$refs.modalTask.resetValidation()
    },
    handleOpenDateDebut(event) {
      if (!event) {
        if (!this.selectedDateTimeDateEnd && !this.selectedDateTimeTimeEnd) {
          this.selectedDateTimeDateEnd = this.selectedDateTimeDateStart
          this.selectedDateTimeTimeEnd = this.selectedDateTimeTimeStart
          this.$refs['dateEnd'].resetValidation()
        }
      }
    },
    async handleValiderTask() {
      if (this.$refs.modalTask.validate()) {
        this.errorMsg = null
        this.processLoader = true
        let data = { ...this.eventToUse }
        data.fields = data.fields.map(i => {
          return { id: i.id, value: i.value }
        })
        const res = await this.validateFunction(data)
        if (res.succes) {
          this.handleClose()
        } else {
          this.errorMsg = res.error
        }
        this.processLoader = false
      }
    },
    handleDatePicker(date, ref) {
      if (date) {
        this.$refs[ref].resetValidation()
      }
    },
    updateFunction() {},
    handelChangeEventType() {
      this.eventToUse.fields = this.eventToUse.type
        ? this.eventToUse.type.fields.map(field => ({ ...field }))
        : []
    },
    updateDateTimeStart() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateStart && this.selectedDateTimeTimeStart) {
        this.eventToUse.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
      } else if (
        this.selectedDateTimeDateStart &&
        !this.selectedDateTimeTimeStart
      ) {
        this.eventToUse.start = `${this.selectedDateTimeDateStart} 00:00`
      } else if (
        !this.selectedDateTimeDateStart &&
        this.selectedDateTimeTimeStart
      ) {
        this.selectedDateTimeDateStart = new Date().toJSON().slice(0, 10)
        this.eventToUse.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
      }
    },
    updateDateTimeEnd() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateEnd && this.selectedDateTimeTimeEnd) {
        this.eventToUse.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
      } else if (
        this.selectedDateTimeDateEnd &&
        !this.selectedDateTimeTimeEnd
      ) {
        this.eventToUse.end = `${this.selectedDateTimeDateEnd} 00:00`
      } else if (
        !this.selectedDateTimeDateEnd &&
        this.selectedDateTimeTimeEnd
      ) {
        this.selectedDateTimeDateEnd = new Date().toJSON().slice(0, 10)
        this.eventToUse.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
      }
    },
    affectEndDate() {
      this.eventToUse.start = this.eventToUse.end
    }
  },
  mounted() {},
  components: {
    ModalRightDynamic,
    EditableCustomFieldForm: () =>
      import('@/components/CrmIla26Component/EditableCustomFieldForm')
  },
  watch: {
    // Écouter les changements dans la date de début
    'eventToUse.start': function(newVal) {
      this.eventToUse.end = newVal
    },
    selectedDateTimeDateStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeTimeStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeDateEnd() {
      this.updateDateTimeEnd()
    },
    selectedDateTimeTimeEnd() {
      this.updateDateTimeEnd()
    }
  }
}
</script>
<style scoped lang="scss">
.line-color-item {
  width: 3px;
  height: 17px;
  border-radius: 9px;
}
.title-list-item {
  gap: 4px;
  display: flex;
  align-items: center;
}
</style>
